import {
    isEmail,
    minDigitsLength,
    maxDigitsLength,
    isNumber,
    isTextFieldNotEmpty,
    isSumm,
} from './../utils/customValidators'
import {
    SUCCESS_MESSAGE,
    SUCCESS_MESSAGE_SHORT,
    ERROR_MESSAGE,
    ERROR_FIELD,
    ERROR_MAIL,
    PHONE_LONG,
    PHONE_SHORT,
    ERROR_NUMBERS_ONLY,
    ACCEPTANCE_FALSE,
    CAPTCHA_FALSE,
} from './../utils/messages'

import IMask from 'imask'

// eslint-disable-next-line no-undef
const FORM_URL = data.ajax_url
let captchaNum1, captchaNum2, encodedNum1, encodedNum2
const forms = document.querySelectorAll('form.order-form')
const input1 = document.createElement('input')
input1.type = 'hidden'
input1.name = 'encoded_num1'
const input2 = document.createElement('input')
input2.type = 'hidden'
input2.name = 'encoded_num2'

function generateCaptcha() {
    const captchaInput = document.querySelector('input[name="check"]')
    if (!isSumm(captchaInput.value, captchaNum1, captchaNum2)) {
        captchaNum1 = Math.floor(Math.random() * 10) + 1
        captchaNum2 = Math.floor(Math.random() * 10) + 1
        encodedNum1 = btoa(captchaNum1.toString())
        encodedNum2 = btoa(captchaNum2.toString())

        // Add the encoded numbers as hidden inputs
        input1.value = encodedNum1
        input2.value = encodedNum2

        forms.forEach((form) => {
            form.querySelector('.check-text').textContent =
                `Антиспам: ${captchaNum1} + ${captchaNum2} = ?*`
            form.appendChild(input1)
            form.appendChild(input2)
        })
    }
}

function highlight(element, msg = ERROR_FIELD) {
    unhighlight(element)
    element.classList.add('error')

    // append error message
    const container = element.closest('.order__input-wrapper')
    const errorElement = document.createElement('small')

    errorElement.classList.add('error-message')
    errorElement.textContent = msg

    container.appendChild(errorElement)
}

function unhighlight(element) {
    element.classList.remove('error')

    // remove error message if exists

    const container = element.closest('.order__input-wrapper')

    if (container) {
        const errorElement = element
            .closest('.order__input-wrapper')
            .querySelector('.error-message')

        if (errorElement) {
            errorElement.remove()
        }
    }
}

function getErrorForInput(input) {
    // For email validation
    if (input.name === 'email') {
        if (!isEmail(input.value)) {
            return ERROR_MAIL
        }
    }

    const textFields = ['your-name']

    if (textFields.includes(input.name)) {
        if (!isTextFieldNotEmpty(input.value)) {
            return ERROR_FIELD
        }
    }

    // For phone validation
    if (input.name === 'phone') {

        if (!isNumber(input.value)) {
            return ERROR_NUMBERS_ONLY
        }

        if (!minDigitsLength(input.value, input, 6)) {
            return PHONE_SHORT
        }
        if (!maxDigitsLength(input.value, input, 11)) {
            return PHONE_LONG
        }
    }

    if (input.name == 'acceptance' && !input.checked) {
        return ACCEPTANCE_FALSE
    }

    // For captcha validation
    if (input.name === 'check') {
        if (!isSumm(input.value, captchaNum1, captchaNum2)) {
            return CAPTCHA_FALSE
        }
    }

    return null
}

function isValidForm(form) {
    let isValid = true

    const inputs = form.querySelectorAll('input')

    inputs.forEach((input) => {
        const isValidValue = isValidInput(input)

        if (!isValidValue) {
            isValid = false
        }
    })

    return isValid
}

function isValidInput(input) {
    const errorMsg = getErrorForInput(input)
    if (errorMsg) {
        highlight(input, errorMsg)

        return false
    }
    unhighlight(input)

    return true
}

export function initFormValidation() {
    generateCaptcha()
    document.querySelectorAll("input[name='phone']").forEach((input) => {
        // eslint-disable-next-line new-cap
        IMask(
            input,
            {
                mask: '+{7} (000) 000-00-00',
            },
        )
    })

    forms.forEach((form) => {
        // add form reset
        form.addEventListener('reset', () => {
            // clear form fields
            form.querySelectorAll('input').forEach((input) => {
                // skip submit input
                if (input.type === 'submit') {
                    return
                }
                // skip hidden inputs
                if (input.type === 'hidden') {
                    return
                }

                if (input.type === 'checkbox') {
                    input.checked = true
                }

                unhighlight(input)
                input.value = ''
            })
        })

        form.addEventListener('submit', (event) => {
            event.preventDefault()

            // Check if body already has loading class
            if (document.body.classList.contains('is-sending')) {
                return
            }

            if (isValidForm(form)) {
                // Add loading class
                document.body.classList.add('is-sending')

                const formData = new FormData(form)
                const isFormShort = form.dataset?.formType === 'short'
                let successMessage = ''

                if (form.dataset?.successMessage) {
                    successMessage = form.dataset.successMessage
                } else if (isFormShort) {
                    successMessage = SUCCESS_MESSAGE_SHORT
                } else {
                    successMessage = SUCCESS_MESSAGE
                }

                fetch(FORM_URL, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => {
                        // Remove loading class
                        document.body.classList.remove('is-sending')

                        if (!response.ok) {
                            throw new Error('Network response was not ok')
                        }

                        return response.json()
                    })
                    .then((result) => {
                        const outputMessage = document.querySelector('.form-row__output')

                        if (result.success) {
                            const modalContent = document.querySelector('.order__modal')

                            modalContent.classList.add('order-modal__hidden')
                            outputMessage.classList.remove('error')
                            outputMessage.classList.add('success')
                            outputMessage.textContent = successMessage
                            // if .modal has .modal--dark class
                            let yandexGoal = 'order_platform'
                            if (document.querySelector('.modal.modal--dark')) {
                                yandexGoal = 'order_franshiza'
                            }

                            try {
                                // eslint-disable-next-line no-undef
                                ym(95519912, 'reachGoal', yandexGoal)
                            } catch (error) {
                                // eslint-disable-next-line no-console
                                console.error('YM undefined:', error)
                            }

                            form.reset()
                        } else {
                            outputMessage.classList.remove('success')
                            outputMessage.classList.add('error')
                            outputMessage.textContent = ERROR_MESSAGE
                        }
                    })
                    .catch((error) => {
                        // Remove loading class
                        document.body.classList.remove('is-sending')

                        // eslint-disable-next-line no-console
                        console.error(error)
                    })
            } else {
                const outputMessage = document.querySelector('.form-row__output')
                outputMessage.classList.remove('success')
                outputMessage.classList.add('error')
                outputMessage.textContent = ERROR_MESSAGE
                generateCaptcha()
            }
        })

        form.addEventListener('input', (event) => {
            if (isValidInput(event.target)) {
                unhighlight(event.target)
            }
        })
    })
}
